import type { NextPage } from 'next';
import { getHomepage } from '../lib';
import PageTemplate from '../templates/page.template';
import { Homepage } from '../types/homepage.interface';
import { MappedOptions } from '../types/mapped-options-and-menus.interface';
import { Menu } from '../types/menu.interface';
import { DocumentRenderer } from '@keystone-6/document-renderer';
import Container from '../components/container/container.component';
import ContactForm from '../components/contact-form/contact-form.component';
import styles from '../styles/homepage.module.scss';
import CarouselWrapper from '../components/carousel-wrapper/carousel-wrapper.component';
import { PostListItem } from '../types/post-list-item.interface';
import SectionTitle from '../components/section-title/section-title.component';
import Image from 'next/image';
import { Helmet } from 'react-helmet';

interface Props {
  menu: Menu;
  options: MappedOptions;
  homepage: Homepage;
  title: string;
  posts: PostListItem[];
}

const Home: NextPage<Props> = ({ menu, options, homepage, title, posts }) => {
  return (
    <PageTemplate
      menu={menu}
      options={options}
      title={title}
      description={options.description}
      isInitiallyDark={true}
    >
      <Helmet>
        <script async src="https://platform.twitter.com/widgets.js"></script>
      </Helmet>
      <header
        id="start"
        className={styles.landing}
        style={{
          backgroundImage: `url(${homepage.landingImage.image.url})`,
          backgroundPosition: 'center',
        }}
      >
        <Container first={true}>
          <div className={styles.img}>
            <Image
              src={`http://localhost:5000${options.brandingImage}`}
              alt={homepage.landingTitle}
              width="636"
              height="275"
            />
          </div>
        </Container>
      </header>
      <section id="about">
        <Container column={true}>
          <div className={styles.introWrapper}>
            <DocumentRenderer document={homepage.intro.document} />
          </div>
        </Container>
      </section>

      <section>
        <Container>
          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              alignSelf: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <span style={{ fontSize: '28px' }}>
              Stay Updated on Our Latest Research:
            </span>
            <span style={{ marginLeft: '20px' }}>
              <a
                className="twitter-follow-button"
                href="https://twitter.com/BerenikeArchaeo"
                data-size="large"
              >
                Follow @BerenikeArchaeo
              </a>
            </span>
          </div>
        </Container>
      </section>

      <section id="db" className={styles.content}>
        <Container column={true}>
          <div className={styles.databaseIntro}>
            <DocumentRenderer document={homepage.callToAction.document} />
          </div>
        </Container>
      </section>

      <section id="contact">
        <Container>
          <div className={styles.contactWrapper}>
            <SectionTitle title="Contact" />
            <p>{homepage.contact}</p>
            <ContactForm />
          </div>
        </Container>
      </section>
      <section>
        <Container column={true} css={{ padding: '100px 0', height: 'auto' }}>
          <div>
            <CarouselWrapper
              images={homepage.sponsors}
              title="Institutions involved and sponsors"
              baseUrl={options.baseUrl}
            />
          </div>
        </Container>
      </section>
    </PageTemplate>
  );
};

export async function getServerSideProps() {
  const homepage = await getHomepage();
  return {
    props: { ...homepage, title: 'Start' },
  };
}

export default Home;
