import React from 'react';
import styles from './section-title.module.scss';

type Props = {
  title: string;
};

const SectionTitle: React.FC<Props> = ({ title }) => (
  <h2 className={styles.SectionTitleContainer}>{title}</h2>
);

export default SectionTitle;
