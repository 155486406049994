import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import SectionTitle from '../section-title/section-title.component';
import { Img } from '../../types/img.interface';

type Props = {
  images: Img[];
  title: string;
  baseUrl: string;
};

const CarouselWrapper: React.FC<Props> = ({ images, title, baseUrl }) => (
  <div className="CarouselContainer">
    <SectionTitle title={title} />
    <Slider
      arrows={false}
      dots={false}
      infinite={true}
      speed={500}
      slidesToShow={1}
      slidesToScroll={1}
      autoplay={true}
    >
      {images.map((img, i) => (
        <img
          className="CarouselImage"
          key={i}
          src={`${baseUrl}${img.image.url}`}
          alt={img.alt}
        />
      ))}
    </Slider>
  </div>
);

export default CarouselWrapper;
