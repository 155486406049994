import React from 'react';
import { useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import styles from './contact-form.module.scss';
import ReactLoading from 'react-loading';
import Button from '../button/button.component';

type Props = {};

interface FormValues {
  name: string;
  lastName: string;
  affiliation: string;
  email: string;
  message: string;
}

const INITIAL_VALUES = {
  name: '',
  lastName: '',
  affiliation: '',
  email: '',
  message: '',
};

const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  lastName: Yup.string().required('Last name is required'),
  affiliation: Yup.string().required('Affiliation is required'),
  email: Yup.string().email('Invalid email').required('E-mail is required'),
  message: Yup.string().required('Message is required'),
});

const ContactForm = (props: Props) => {
  const formik = useFormik<FormValues>({
    validationSchema: VALIDATION_SCHEMA,
    initialValues: INITIAL_VALUES,
    onSubmit: async (values) => {
      try {
        formik.setSubmitting(true);
        const response = await fetch('/api/contact', {
          method: 'POST',
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        });
        const data = await response.json();
        if (response.status === 200) {
          toast(data.message, { type: 'success' });
          formik.resetForm();
        } else {
          toast('Something went wrong, please try again later.', {
            type: 'error',
          });
        }
        formik.setSubmitting(false);
      } catch (error) {
        toast(error as any, { type: 'error' });
        formik.setSubmitting(false);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className={styles.form}>
      <div className={styles.name}>
        <label htmlFor="name">Name</label>
        <input
          type="text"
          id="name"
          name="name"
          onChange={formik.handleChange}
          disabled={formik.isSubmitting}
          value={formik.values.name}
          autoComplete="off"
        />
        {formik.errors.name && formik.touched.name ? (
          <small>{formik.errors.name}</small>
        ) : null}
      </div>
      <div className={styles.lastName}>
        <label htmlFor="lastName">Last name</label>
        <input
          type="text"
          id="lastName"
          name="lastName"
          onChange={formik.handleChange}
          disabled={formik.isSubmitting}
          value={formik.values.lastName}
          autoComplete="off"
        />
        {formik.errors.lastName && formik.touched.lastName ? (
          <small>{formik.errors.lastName}</small>
        ) : null}
      </div>
      <div className={styles.affiliation}>
        <label htmlFor="affiliation">Affiliation</label>
        <input
          type="text"
          id="affiliation"
          name="affiliation"
          onChange={formik.handleChange}
          disabled={formik.isSubmitting}
          value={formik.values.affiliation}
          autoComplete="off"
        />
        {formik.errors.affiliation && formik.touched.affiliation ? (
          <small>{formik.errors.affiliation}</small>
        ) : null}
      </div>
      <div className={styles.email}>
        <label htmlFor="email">E-mail</label>
        <input
          type="text"
          id="email"
          name="email"
          onChange={formik.handleChange}
          disabled={formik.isSubmitting}
          value={formik.values.email}
        />
        {formik.errors.email && formik.touched.email ? (
          <small>{formik.errors.email}</small>
        ) : null}
      </div>
      <div className={styles.message}>
        <label htmlFor="message">Message</label>
        <textarea
          id="message"
          name="message"
          onChange={formik.handleChange}
          disabled={formik.isSubmitting}
          value={formik.values.message}
          rows={5}
        />
        {formik.errors.message && formik.touched.message ? (
          <small>{formik.errors.message}</small>
        ) : null}
      </div>
      <div className={styles.submit}>
        <Button type="submit" btn={true}>
          {formik.isSubmitting ? (
            <ReactLoading type={'bubbles'} className={styles.loader} />
          ) : (
            'Submit'
          )}
        </Button>
      </div>
      <ToastContainer />
    </form>
  );
};

export default ContactForm;
